<template>
  <page-view title="电费账单" left-arrow :nav-border="false" custom-class="page__yhzd-query">
    <template #nav-right>
      <yhbh-select v-model="form.yhbh" @confirm="yhbhChangeHandle" :custom-style="{color: '#fff'}"/>
    </template>
    <loading-layout ref="loadingLayoutOuter"  error-text="绑定户号信息查询失败" empty-text="未绑定用户编号">
      <template #empty>
        <yhbh-empty fromPage="yhzd-query"/>
      </template>
      <div class="top">
        <div class="yh-zhxx">
          <dl>
            <dt>户名：</dt>
            <dd>{{yhJbxx.yhmc}}</dd>
          </dl>
          <dl>
            <dt>户号：</dt>
            <dd>{{yhJbxx.yhbh}}</dd>
          </dl>
          <dl>
            <dt>用电地址：</dt>
            <dd>{{yhJbxx.yhdz}}</dd>
          </dl>
          <dl>
            <dt>预付余额：</dt>
            <dd>{{yhJbxx.dqjy | currency}}</dd>
          </dl>
          <dl>
            <dt>总欠费：</dt>
            <dd :style="{display: 'inline-flex', alignItems: 'center'}">
              <span style="display: inline-block;">{{yhJbxx.dqqf | currency}}</span>
              <van-button 
                v-if="isShowFunc('dfcj') && yhJbxx.dqqf > 0"
                type="warning" 
                size="small" 
                :style="{
                  padding: '0 12px',
                  height: '26px',
                  borderRadius: '3px',
                  marginLeft: '10px'
                }"
                :to="'/dfcj/'+yhJbxx.yhbh"
              >
                缴纳欠费
              </van-button>
            </dd>
          </dl>
        </div>
      </div>

      <van-cell title="账单列表">
        <template #icon>
          <svg-icon icon-class="bill" size="20" color="#00b1ff" style="margin-right: 10px"/>
        </template>
      </van-cell>
      <loading-layout ref="loadingLayout" @reaty="fetchYhjyzd">
        <van-tabs v-model="activeTabIndex" scrollspy sticky offset-top="44" title-active-color="#00b1ff" color="#00b1ff">
          <van-tab v-for="(yearItem,index) in yhzdList" :title="yearItem.nf+ ' 年'" :key="index">
            <div class="yhzd-list container">
              <yhzd-item v-for="(item, index) in yearItem.list" :key="index" :source-data="item"/>
            </div>
          </van-tab>
        </van-tabs>
      </loading-layout>
    </loading-layout>
  </page-view>
</template>

<script>
import { DropdownMenu, DropdownItem, Toast, Tab, Tabs } from 'vant';
import { mapGetters } from 'vuex'
import YhzdItem from '@/components/YhzdItem/index.vue';
import {yhJbxx_fetchOneYhJbxxByYhbh, zwYhzdxx_getYhjyzdByYhbhOrNf} from '@/api/zhcx';
import { zwLjfysmx_getDfzdByYhbh } from '@/api/dfcj'
import Loading from '@/components/LoadingLayout/loading.vue';
import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
import YhbhSelect from '@/components/YhbhSelect/index.vue';
export default {
  name: 'YhzdQuery',
  components: {
    'van-dropdown-menu':DropdownMenu, 
    'van-dropdown-item': DropdownItem,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    YhzdItem,
    Loading,
    YhbhEmpty,
    YhbhSelect,
  },
  data() {
    return {
      form: {
        yhbh: '',
        nf: 2021,
      },
      years: [],
      yhbhList: [],
      yhJbxx: {},
      yhzdList: [],
      zdLoading: false,
      activeTabIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['jgbm']),
    funcList() {
      let list = [];
      switch(this.jgbm) {
        case "1R6":
          list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'ksjf', 'gdcx', 'dfcj','yfkcr'];
          break;
        case "11":
          list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'ksjf', 'gdcx', 'dzhtgl', 'dfcj','yfkcr'];
          break;
        default:
          list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'bankPayment', 'gdcx', 'dzhtgl'];
          break;
      }
      return list;
    }
  },
  methods: {
    initYears() {
      let year = new Date().getFullYear();
      this.years = [0,1,2,3,4].map(item => {
        let val = year - item;
        return {
          text: val.toString(),
          value: val,
        }
      })
    },
    fetchYhJbxx() {
      this.$toast.loading('加载中...') 
      yhJbxx_fetchOneYhJbxxByYhbh(this.form.yhbh).then(res => {
        this.yhJbxx = res.content;
        this.fetchYhjyzd();
      }).finally(() => {
        this.$toast.clear();
      });
    },
    fetchYhjyzd() {
      this.yhzdList = [];
      const {nf, yhbh} = this.form;
      this.$refs['loadingLayout'].setStatus('loading')
      zwLjfysmx_getDfzdByYhbh(yhbh).then(res => {
        let list = [];
        // 数据预处理
        let listMap = {};
        for (let [i,v] of res.content.entries()) {
          if (listMap[v.nf]) {
            listMap[v.nf].push(v);
          } else {
            listMap[v.nf] = [v];
          }
        }
        for (let key of Object.keys(listMap)) {
          list.push({
            nf: key,
            list: listMap[key]
          });
        }
        list.sort(function(a,b) {
          return b.nf - a.nf;
        });
        this.yhzdList = list;
        if (this.yhzdList.length == 0) {
          this.$refs['loadingLayout'].setStatus('empty')
        } else {
          this.$refs['loadingLayout'].setStatus('success')
        }
      }).catch(err => {
        console.log(err)
        this.$refs['loadingLayout'].setStatus('net-error')
      })
    },
    yhbhChangeHandle(yhxx) {
      this.form.yhbh = yhxx.yhbh;
      this.fetchYhJbxx();
    },
    isShowFunc(funcName) {
      return this.funcList.indexOf(funcName) != -1;
    },
  },
  mounted() {
    this.$refs['loadingLayoutOuter'].setStatus('loading');
    this.$store.dispatch('getYhbhList').then(list => {
      this.yhbhList = list;
      if (list.length == 0) {
        this.$refs['loadingLayoutOuter'].setStatus('empty');
      } else {
        this.$refs['loadingLayoutOuter'].setStatus('success');
        this.form.yhbh = this.$route.query.yhbh ? this.$route.query.yhbh : list[0].yhbh;
        this.fetchYhJbxx();
      }
    }).catch(err => {
      this.$refs['loadingLayoutOuter'].setStatus('net-error');
    });
  },
  created() {
    this.initYears();
  }
}
</script>


<style lang="scss" scoped>
$top-bg-color: #00b1ff;

.page__yhzd-query /deep/ {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .van-nav-bar {
    background-color:  $top-bg-color;
  }
  .van-nav-bar .van-icon {
    color: #fff;
  }
  .van-nav-bar__title {
    color: #fff;
  }
  .van-nav-bar__right {
    padding: 0;
  }
  .top {
    flex-basis: 152px;
    background-color: $top-bg-color;
    position: relative;
  }
  .top::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/home/city-shadow.png');
    background-position: bottom;
    background-size: auto 90%;
    background-repeat: no-repeat;
    opacity: 0.13;
  }
  .bottom {
    margin-top: 10px;
  }
  .scrollBox {
    flex: 1;
    overflow-y: auto;
  }

  .yh-zhxx {
    position: relative;
    z-index: 1;
    padding: 20px 40px;
    color: #fff;
    font-size: 14px;
    background: rgba(0,177, 255,0.68);
    dl {
      margin: 0;
      line-height: 26px;
    }
    dt, dd {
      display: inline-block;
    }
    dd {
      margin-left: 0;
    }
  }
  .van-dropdown-menu {
    border: 1px solid $top-bg-color;
    border-radius: 15px;
  }
  .van-dropdown-menu__bar {
    box-shadow: none;
    height: 1.5em;
  }
  .van-dropdown-menu__title {
    color: $top-bg-color;
  }
}
</style>
