<template>
  <div class="yhzd-item">
    <span class="yhzd-item__zwyf">{{sourceData.zwyf+''}}</span>
    <van-cell-group :border="false">
      <van-cell :border="false" title="合计电量:" :value="sourceData.hjdl"/>
      <van-cell :border="false" title="应收电费:" :value="sourceData.ysdf | currency"/>
      <van-cell :border="false" title="欠费金额:" :value="sourceData.qfje | currency"/>
      <van-cell :border="false" title="备注:" value=""/>
      <van-cell>
        <van-button type="primary" block size="small" @click="handleViewSfqd">查看算费清单</van-button>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'YhzdItem',
  props: {
    sourceData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    handleViewSfqd() {
      let query = {
        zwyf: this.sourceData.zwyf,
        jgbm: this.sourceData.jgbm,
        yhbh: this.sourceData.yhbh,
        yhmc: this.sourceData.yhmc,
        ywbh: this.sourceData.ywbh,
      }

      this.$router.push({name: 'sfqd', query: query});
    }
  },
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/variable.scss';
  .yhzd-item /deep/{
    padding: 16px 0;
    margin: 10px 0;
    border-radius: 4px;
    overflow: hidden  ;
    background-color: #fff;
    
    .van-cell {
      padding: 5px 16px;
    }
  }

  .yhzd-item__zwyf {
    display: inline-block;
    background: $color-gray-400;
    padding: 2px 12px;
    font-size: 14px;
    color: #fff;
  }
</style>